import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
import * as sbc from '@tvlgiao/bc-supermarket-builder-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "home-page"
    }}>{`Home Page`}</h1>
    <h2 {...{
      "id": "rearrange-home-page-sections"
    }}>{`Rearrange Home Page Sections`}</h2>
    <p>{`The home page content is divided into sections. You can show or hide any section or rearrange the sections' order in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Home Page`}</strong>{` > `}<strong parentName="p">{`Sections`}</strong>{`.`}</p>
    <h3 {...{
      "id": "tutorial-video"
    }}>{`Tutorial video`}</h3>
    <p>{`Watch this video for how to hide and rearrange the sections' order.`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/0wILFdym2M4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2 {...{
      "id": "home-page-carousels"
    }}>{`Home Page Carousels`}</h2>
    <p><img alt="Home 1 Main Carousel" src={require("../img/home1-main-carousel.jpg")} /></p>
    <h3 {...{
      "id": "edit-the-carousel"
    }}>{`Edit the carousel`}</h3>
    <p>{`Edit the home page carousel in `}<strong parentName="p">{`Storefront Design`}</strong>{` > `}<strong parentName="p">{`Design Options`}</strong>{`:`}</p>
    <p><img alt="Edit homepage carousel" src={require("../img/edit-homepage-carousel.png")} /></p>
    <h3 {...{
      "id": "colors-customization"
    }}>{`Colors customization`}</h3>
    <p>{`To customize the carousel colors, find `}<strong parentName="p">{`Carousel`}</strong>{` section in the Theme Editor:`}</p>
    <p><img alt="Theme editor carousel" src={require("../img/theme-editor-carousel.png")} /></p>
    <h3 {...{
      "id": "hide-the-carousel"
    }}>{`Hide the carousel`}</h3>
    <p>{`To hide the main carousel on Home Page, uncheck `}<strong parentName="p">{`Show Carousel`}</strong>{` checkbox in `}<strong parentName="p">{`Carousel`}</strong>{` section of Theme Editor.`}</p>
    <h3 {...{
      "id": "show-videos-on-the-carousel"
    }}>{`Show videos on the carousel`}</h3>
    <p>{`To display YouTube videos on Home Page Carousel, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Carousel`}</strong>{` > input your `}<em parentName="p">{`YouTube video ID`}</em>{` in the input fields as showing below:`}</p>
    <p><img alt="Edit carousel videos" src={require("../img/theme-editor-edit-carousel-videos.png")} /></p>
    <p><strong parentName="p">{`Video 1`}</strong>{` will be put on the first slide, `}<strong parentName="p">{`Video 3`}</strong>{` will be put on the 3rd slide and so on.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Note: you much upload images on the slides at position 1 and position 3 in order to display videos on these slides.`}</em></strong></p>
    <p>{`Example how to display videos on slide 1 and slide 3:`}</p>
    <p><img alt="example editing carousel for showing videos" src={require("../img/edit-carousel-for-videos.png")} /></p>
    <p><strong parentName="p">{`Height/Width ratio percent`}</strong>{` is percent of your video height divide width, for example the size of video is 1920x1080, then input `}<inlineCode parentName="p">{`56.25%`}</inlineCode>{` into this field.`}</p>
    <h2 {...{
      "id": "2-banners-beside-the-carousel"
    }}>{`2 banners beside the carousel`}</h2>
    <p>{`Enable in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Carousel`}</strong>{` > tick on `}<strong parentName="p">{`Enable side banners?`}</strong>{`.`}</p>
    <p>{`In `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, click button `}<strong parentName="p">{`Create a Banner`}</strong>{`. In `}<strong parentName="p">{`Banner Content`}</strong>{` box, click the square `}<inlineCode parentName="p">{`HTML`}</inlineCode>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <sbc.CarouselSideBannerBuilderComponent />
    <p>{`Choose other options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on page`}</strong>{`: `}<inlineCode parentName="li">{`Home Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "3-banners-on-home-page"
    }}>{`3 Banners on Home Page`}</h2>
    <p><img alt="3 banners on homepage" src={require("../img/home1-3-banners.jpg")} /></p>
    <p>{`To show these banners on homepage, go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. In `}<strong parentName="p">{`Banner Content`}</strong>{` editor, click `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p><img alt="Edit homepage 3 banners" src={require("../img/edit-home1-3-banners.png")} /></p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <sbc.HomeThreeBannersBuilderComponent />
    <p>{`Choose other options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{` = `}<inlineCode parentName="li">{`Home Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{` = `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <p><img alt="Show on Page & Location" src={require("../img/edit-banner-show-on-page-location.png")} /></p>
    <p>{`Click `}<strong parentName="p">{`Save`}</strong>{` button to finish.`}</p>
    <p>{`You can freely edit the HTML above to change banner images or links but keep the HTML attribute `}<inlineCode parentName="p">{`id="emthemesModezBannersBlockHome01"`}</inlineCode>{` unchanged.`}</p>
    <h3 {...{
      "id": "tutorial-video-1"
    }}>{`Tutorial video`}</h3>
    <p>{`Watch the video below for step by step instruction:`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/Yp0NXGn9lBY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2 {...{
      "id": "featured--bestselling--new--products-tabs"
    }}>{`Featured / Bestselling / New  Products Tabs`}</h2>
    <p><img alt="Special products tabs" src={require("../img/home1-special-product-tabs.jpg")} /></p>
    <p>{`You can configure this section in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{`:`}</p>
    <p><img alt="Theme Editor Special Products Tabs" src={require("../img/theme-editor-special-products-tabs.png")} /></p>
    <h3 {...{
      "id": "optimize-for-loading-speed"
    }}>{`Optimize for loading speed`}</h3>
    <p>{`Showing many products may affects to your website loading speed. In order to optimize the loading speed (TTFB - Time to first byte) while still loading many products, you can configure value of `}<strong parentName="p">{`Show more products when the page loaded`}</strong>{` lower, for example `}<inlineCode parentName="p">{`4`}</inlineCode>{` and set `}<strong parentName="p">{`Show more products when scrolling to the viewport`}</strong>{` higher, for example `}<inlineCode parentName="p">{`8`}</inlineCode>{`. As a result, when opening the home page, 4 products will be displayed immediately, when scrolling down to this section, additional 8 products will be loaded and displayed next.`}</p>
    <p>{`In addition, `}<strong parentName="p">{`Show Load More button`}</strong>{` option allows displaying a Load More button that when clicked will load and display more products. The maximium number of products that can be displayed is 25 for new products, 100 for bestselling and featured.`}</p>
    <h2 {...{
      "id": "products-by-categories"
    }}>{`Products By Categories`}</h2>
    <p><img alt="Products by category on homepage" src={require("../img/home1-products-by-category.jpg")} /></p>
    <p>{`This section will show products organized categories. You can choose number of categories to display products. Categories are sorted by the order specified in `}<strong parentName="p">{`Product Categories`}</strong>{` in admin manager.`}</p>
    <h3 {...{
      "id": "configuration"
    }}>{`Configuration`}</h3>
    <p>{`To configure this section, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Products by Category with Sorting Tabs`}</strong>{`:`}</p>
    <p><img alt="Configure products by category" src={require("../img/theme-editor-products-by-category.png")} /></p>
    <h3 {...{
      "id": "optimize-for-execution-time"
    }}>{`Optimize for execution time`}</h3>
    <p>{`By default, all products will be loaded and displayed when opening the page. At a result, it takes a longer execution time that can affect your site's Page Speed score. To avoid this, you can select `}<strong parentName="p">{`Only load when scrolling to the viewport`}</strong>{` option, the products won't loaded until user scrolls down to this area.`}</p>
    <h3 {...{
      "id": "specify-which-categories-to-display"
    }}>{`Specify which categories to display`}</h3>
    <p>{`By default, the product categories displayed are the top categories in the order in which they are sorted on the main navigation. You can limit number of categories to display by setting `}<strong parentName="p">{`Number of Categories`}</strong>{` option. `}</p>
    <p>{`You can manually specify which categories should be displayed by setting the categories ID to `}<strong parentName="p">{`Category ID`}</strong>{` box, separated by comma. For example: `}<inlineCode parentName="p">{`200,221,254,275`}</inlineCode>{`. Check instruction `}<a parentName="p" {...{
        "href": "https://solidcommerce.zendesk.com/hc/en-us/articles/215722843-Finding-Bigcommerce-Category-Names-and-ID-Numbers#automark4"
      }}>{`How to find the category ID`}</a>{`. Note that `}<strong parentName="p">{`Number of Categories`}</strong>{` will be ignored if you choose to display categories manually.`}</p>
    <h3 {...{
      "id": "show-banner-per-category"
    }}>{`Show Banner per Category`}</h3>
    <p><img alt="products-by-category-banner" src={require("../img/products-by-category-banner.png")} /></p>
    <p>{`To show the banner image per products by category block, tick on option `}<strong parentName="p">{`Show banner`}</strong>{` in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Products by Category with Sorting Tabs`}</strong>{`:`}</p>
    <p><img alt="theme-editor-show-banner-on-products-by-category" src={require("../img/theme-editor-show-banner-on-products-by-category.png")} /></p>
    <p>{`Then go to `}<strong parentName="p">{`Storefront`}</strong>{` > `}<strong parentName="p">{`Image Manager`}</strong>{`, upload your banner image with name following this rule: `}<inlineCode parentName="p">{`products-by-category-{i}`}</inlineCode>{` where `}<inlineCode parentName="p">{`{i}`}</inlineCode>{` is replaced by category order starting from 1. The banner image format must be `}<strong parentName="p">{`jpg`}</strong>{`, it means the image on your computer must be `}<inlineCode parentName="p">{`products-by-category-1.jpg`}</inlineCode>{` if it's used for the first category. All letters must be lower case.`}</p>
    <p><strong parentName="p">{`Watch this tutorial video:`}</strong></p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/0cWTw4xr_w0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h3 {...{
      "id": "hot-categories"
    }}>{`Hot Categories`}</h3>
    <p><strong parentName="p">{`Hot Categories`}</strong>{` appears on the right is the sub-categories of the current category.`}</p>
    <p><img alt="Hot categories" src={require("../img/home1-products-by-category-hot-categories.png")} /></p>
    <h3 {...{
      "id": "display-featured-product-items"
    }}>{`Display featured product items`}</h3>
    <p>{`To show products sorted by `}<em parentName="p">{`Featured`}</em>{`, edit the products, change the `}<strong parentName="p">{`Sort Order`}</strong>{` value to smaller than others. By default, Sort Order value is 0, therefore you need to update the products you want to display by featured order a smaller value, negative number also works well.`}</p>
    <p>{`Please note that products by category sorted by `}<em parentName="p">{`featured item`}</em>{` are different than `}<strong parentName="p">{`featured products`}</strong>{`. Your featured products (which are marked stars) won't show here if the `}<strong parentName="p">{`Sort Order`}</strong>{` value is not set correctly.`}</p>
    <p><strong parentName="p">{`Watch this video for step by step instruction:`}</strong></p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/lV931JCfvEc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2 {...{
      "id": "top-categories"
    }}>{`Top Categories`}</h2>
    <p><img alt="Top categories on homepage" src={require("../img/home1-top-categories.jpg")} /></p>
    <p>{`This block show top categories and its sub-categories sorted by the order set in Product Categories manager.`}</p>
    <h3 {...{
      "id": "configuration-1"
    }}>{`Configuration`}</h3>
    <p>{`You can configure number of categories to display in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Top Categories`}</strong>{`:`}</p>
    <p><img alt="Configure Top Categories" src={require("../img/theme-editor-top-categories.png")} /></p>
    <h3 {...{
      "id": "show-thumbnails"
    }}>{`Show Thumbnails`}</h3>
    <p><img alt="theme-editor-show-thumbnails-of-top-categories" src={require("../img/theme-editor-show-thumbnails-of-top-categories.png")} /></p>
    <p>{`To show thumbnail images beside each category, tick on `}<strong parentName="p">{`Show thumbnails`}</strong>{` in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Top Categories Of This Month`}</strong>{`.`}</p>
    <p>{`Then go to `}<strong parentName="p">{`Storefront`}</strong>{` > `}<strong parentName="p">{`Image Manager`}</strong>{`, upload your thumbnail images with appropriated name: `}<inlineCode parentName="p">{`categories-list-thumbnail-{i}`}</inlineCode>{` where `}<inlineCode parentName="p">{`{i}`}</inlineCode>{` is replaced by category order starting from 1. The thumbnail image format must be `}<strong parentName="p">{`jpg`}</strong>{`, it means the image on your computer must be `}<inlineCode parentName="p">{`categories-list-thumbnail-1.jpg`}</inlineCode>{` if it's used for the first category. All letters must be lower case.`}</p>
    <h2 {...{
      "id": "fullwidth-banner-on-home-page"
    }}>{`Fullwidth Banner on Home Page`}</h2>
    <p><img alt="fullwidth banner on homepage" src={require("../img/home1-fullwidth-banner.jpg")} /></p>
    <p>{`To show this banner on Home Page, Go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. In `}<strong parentName="p">{`Banner Content`}</strong>{` editor, click `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p><img alt="Edit homepage fullwidth banner" src={require("../img/edit-home1-fullwidth-banner.png")} /></p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <sbc.HomeFullwidthBannerBuilderComponent />
    <p>{`Choose other options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{` = `}<inlineCode parentName="li">{`Home Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{` = `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <p><img alt="Show on Page & Location" src={require("../img/edit-banner-show-on-page-location.png")} /></p>
    <p>{`Click `}<strong parentName="p">{`Save`}</strong>{` button to finish.`}</p>
    <p>{`You can freely edit the HTML code above to change banner images or links but keep the HTML attribute `}<inlineCode parentName="p">{`id="emthemesModezBannersBlockHome02"`}</inlineCode>{` unchanged.`}</p>
    <h2 {...{
      "id": "brand-carousel"
    }}>{`Brand Carousel`}</h2>
    <p><img alt="Brand Carousel" src={require("../img/home1-brand-carousel.png")} /></p>
    <p>{`To show this carousel on Home Page, go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. In `}<strong parentName="p">{`Banner Content`}</strong>{` editor, click `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <sbc.HomeBrandCarouselBuilderComponent />
    <p>{`Then click `}<strong parentName="p">{`Update`}</strong>{` button to go back the previous screen.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{` = `}<inlineCode parentName="li">{`Home Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{` = `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <p>{`Click `}<strong parentName="p">{`Save`}</strong>{` button to finish.`}</p>
    <p>{`You can freely edit the HTML above to change banner images or links but keep the HTML attribute `}<inlineCode parentName="p">{`id="emthemesModezBannersBlockHome03"`}</inlineCode>{` unchanged.`}</p>
    <h2 {...{
      "id": "special-products-columns"
    }}>{`Special Products Columns`}</h2>
    <p><img alt="Special product columns" src={require("../img/home1-special-product-columns.jpg")} /></p>
    <p>{`This section shows bestselling products, featured products and new products in 3 columns.`}</p>
    <p>{`You can configure this section in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Special Products Columns`}</strong>{`:`}</p>
    <p><img alt="Configure special products columns" src={require("../img/theme-editor-special-products-columns.png")} /></p>
    <h2 {...{
      "id": "recent-posts"
    }}>{`Recent Posts`}</h2>
    <p>{`To show Recent Posts section on Home Page, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > set `}<strong parentName="p">{`Recent blog posts`}</strong>{` = number of posts to show up.`}</p>
    <h2 {...{
      "id": "instagram"
    }}>{`Instagram`}</h2>
    <p>{`To show your Intagram photos on Home Page, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Instagram`}</strong>{`, input your `}<strong parentName="p">{`Access Token`}</strong>{` code and `}<strong parentName="p">{`User ID`}</strong>{`.`}</p>
    <ul>
      <li parentName="ul">{`To retrieve your Access Token, use this tool `}<a parentName="li" {...{
          "href": "http://bit.ly/2Cm0I1O"
        }}>{`http://bit.ly/2Cm0I1O`}</a></li>
      <li parentName="ul">{`To retrieve your User ID, use this tool `}<a parentName="li" {...{
          "href": "http://bit.ly/2Et7ULx"
        }}>{`http://bit.ly/2Et7ULx`}</a></li>
    </ul>
    <p>{`Choose `}<strong parentName="p">{`Number of photos`}</strong>{` to show up.`}</p>
    <h2 {...{
      "id": "newsletter-popup"
    }}>{`Newsletter Popup`}</h2>
    <p><img alt="theme-editor-newsletter-popup" src={require("../img/theme-editor-newsletter-popup.png")} /></p>
    <p>{`To configure Newsletter Popup, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Newsletter Popup`}</strong>{`.`}</p>
    <p>{`To edit the popup content, create a new banner in `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, click button `}<strong parentName="p">{`Create a Banner`}</strong>{`. In `}<strong parentName="p">{`Banner Content`}</strong>{` box, click the square `}<inlineCode parentName="p">{`HTML`}</inlineCode>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p><img alt="edit-banner-newsletter-popup" src={require("../img/edit-banner-newsletter-popup.png")} /></p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <sbc.NewsletterPopupBuilderComponent />
    <p>{`Choose other options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      